import React, {useEffect, useState} from 'react';
import './DetailsPanel.css';
import {Line} from 'react-chartjs-2';
import logo from './assets/ocb-logo.png';

const formatNumber = (num) => {
  return new Intl.NumberFormat('en-US', {maximumSignificantDigits: 13}).format(
      num,
  );
}

const data = [-121, -300, -304, 532, -732, -1235, 347, -5763];

const dummyData = {
  labels: ['2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024'],
  datasets: [
    {
      label: 'Reduction Initiatives',
      data: data,
      fill: true,
      tension: 0.4,
      pointBackgroundColor: 'rgba(0, 123, 255, 1)',
      pointBorderColor: '#fff',
      backgroundColor: 'rgba(255, 200, 0, 0.3)'
    },
  ],
};
const lineOptions = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      ticks: {
        display: false
      }
    }
  }
};
const DetailsPanel = ({branch, branches, setSelectedRegion}) => {
  const [activeTab, setActiveTab] = useState('summary');
  const [summary, setSummary] = useState({
    totalEmployees: 0,
    emissions: {
      total: 0,
      scope1: 0,
      scope2: 0,
      scope3: 0,
    },
    reduction: {
      electricity: 0,
      fuel: 0,
    },
    consumption: {
      electricity: 0,
      fuel: 0,
    },
    initiatives: {
      emissions: 123,
      electricCars: 0,
      solarPower: 0,
      treesPlanted: 0,
    }
  });
  const showSummary = () => {
    setActiveTab('summary');
    setSelectedRegion(null);
  }

  useEffect(() => {
    if (branches && branches.length > 0) {
      const combined = branches.reduce((acc, curr) => {
        acc.totalEmployees += curr.totalEmployees;
        acc.emissions.scope1 += curr.emissions.scope1;
        acc.emissions.scope2 += curr.emissions.scope2;
        acc.emissions.scope3 += curr.emissions.scope3;
        acc.consumption.electricity += curr.consumption.electricity;
        acc.consumption.fuel += curr.consumption.fuel;
        acc.initiatives.emissions += curr.initiatives.emissions;
        acc.initiatives.electricCars += curr.initiatives.electricCars;
        acc.initiatives.solarPower += curr.initiatives.solarPower;
        acc.initiatives.treesPlanted += curr.initiatives.treesPlanted;
        return acc;
      }, {
        totalEmployees: 0,
        emissions: {
          total: 0,
          scope1: 0,
          scope2: 0,
          scope3: 0,
        },
        reduction: {
          electricity: 0,
          fuel: 0,
        },
        consumption: {
          electricity: 0,
          fuel: 0,
        },
        initiatives: {
          emissions: 123,
          electricCars: 0,
          solarPower: 0,
          treesPlanted: 0,
        }
      });
      combined.reduction.electricity = 56.32;
      combined.reduction.fuel = 34.98;
      setSummary(combined);
    }
  }, [branches]);

  return (
      <div className="details-panel">
        <div className="net-zero-emissions">
          <h2>Net Zero Emissions Target</h2>
          <div className="emissions-progress">
            <span className="baseline">Baseline 2020</span>
            <div className="progress-bar">
              <div className="progress" style={{width: '73%'}}></div>
            </div>
            <span className="target">Target 2030</span>
          </div>
        </div>

        <div className="region-summary">
          <div style={{
            display: "flex",
            justifyContent: "center",
            margin: '20px'
          }}>
            <img className="logo" alt="OCB Branches"
                 src={logo} height="50"/>
          </div>
          <div className="summary-tabs">
            <button className={`tab ${activeTab === 'summary' ? 'active' : ''}`}
                    onClick={() => showSummary()}>Summary
            </button>
            <button
                className={`tab ${activeTab === 'branches' ? 'active' : ''}`}
                onClick={() => setActiveTab('branches')}>Branch & Units
            </button>
          </div>

          {!branch && activeTab === 'summary' && (
              <>
                <h2>Bankwide Summary</h2>
                <div className="emission-info">
                  <div className="emission-card value-card highlight">
                    <h4>Total carbon footprint</h4>
                    <p>tCO2e</p>
                    <p className="emission-value">{formatNumber(
                        summary.emissions.scope1 + summary.emissions.scope2
                        + summary.emissions.scope3)}</p>
                    <p>Equivalent to {formatNumber(
                        (summary.emissions.scope1 + summary.emissions.scope2
                            + summary.emissions.scope3) * 554886.58)} Mangrove
                      (Rhizophora apiculata) in 8 years of lifespan</p>
                  </div>
                  <div className="emission-card value-card">
                    <h4>Emission scope 1</h4>
                    <p>tCO2e</p>
                    <p className="emission-value">{formatNumber(
                        summary.emissions?.scope1 || 0)}</p>
                    <p>Direct emissions from owned sources</p>
                  </div>
                  <div className="emission-card value-card">
                    <h4>Emission scope 2</h4>
                    <p>tCO2e</p>
                    <p className="emission-value">{formatNumber(
                        summary.emissions?.scope2 || 0)}</p>
                    <p>Indirect emissions from the purchased energy</p>
                  </div>
                  <div className="emission-card value-card">
                    <h4>Emission scope 3</h4>
                    <p>tCO2e</p>
                    <p className="emission-value">{formatNumber(
                        summary.emissions?.scope3 || 0)}</p>
                    <p>Indirect emissions from activities in the value chain</p>
                  </div>
                </div>

                <div className="reduction-initiatives">
                  <h4>Reduction Initiatives</h4>
                  <p className="emission-value">-5,763 tCO2e</p>
                  <p className="equivalent-value">Equivalent to 192,112.7
                    Mangrove (Rhizophora apiculata) in 8 years of lifespan</p>
                  <div className="chart-container">
                    <Line data={dummyData} options={lineOptions}/>
                  </div>
                </div>

                <div className="additional-info">
                  <div className="info-card value-card">
                    <h4>Green project</h4>
                    <p>tCO2e</p>
                    <p className="info-value">{formatNumber(
                        summary.initiatives.emissions)}</p>
                  </div>
                  <div className="info-card value-card">
                    <h4>Electric vehicle</h4>
                    <p>Unit</p>
                    <p className="info-value">{formatNumber(
                        summary.initiatives.electricCars)}</p>
                  </div>
                  <div className="info-card value-card">
                    <h4>Solar panel capacity</h4>
                    <p>kWp</p>
                    <p className="info-value">{formatNumber(
                        summary.initiatives.solarPower)}</p>
                  </div>
                  <div className="info-card value-card">
                    <h4>Tree planting</h4>
                    <p>Trees</p>
                    <p className="info-value">{formatNumber(
                        summary.initiatives.treesPlanted)}</p>
                  </div>
                </div>

                <div className="energy-consumption">
                  <div className="consumption-card value-card">
                    <h4>Electricity usage</h4>
                    <p>kWh</p>
                    <p className="consumption-value">{formatNumber(
                        summary.consumption.electricity)}</p>
                    <p className="percentage better">{formatNumber(
                        summary.reduction.electricity)}% ↓</p>
                  </div>
                  <div className="consumption-card value-card">
                    <h4>Fuel consumptions</h4>
                    <p>Liter</p>
                    <p className="consumption-value">{formatNumber(
                        summary.consumption.fuel)}</p>
                    <p className="percentage better">{formatNumber(
                        summary.reduction.fuel)}% ↓</p>
                  </div>
                </div>

                <div className="footer">
                  <p>Powered by <img
                      src={"https://aquila.is/wp-content/uploads/2024/04/aquila-logo-blue.png"}
                      width="100" alt={"AQUILA"}/></p>
                  <p>Bank OCB</p>
                </div>
              </>
          )}

          {activeTab === 'branches' && (
              <div className="branches-list">
                {branches.map((branch, index) => (
                    <div key={index} className="branch-card">
                      <h4>{branch.branch}</h4>
                      <p>{branch.details}</p>
                      <p style={{
                        borderTop: "1px solid #dddddd",
                        paddingTop: '20px'
                      }}>
                        <span> <strong>Total Emission:</strong> {formatNumber(
                            branch.emissions.scope1 + branch.emissions.scope2
                            + branch.emissions.scope3)} </span>
                        tCO2e
                      </p>
                    </div>
                ))}
              </div>
          )}

          {activeTab === 'summary' && branch && (
              <>
                <div className="region-info">
                  <img src={"./ocb-location.jpg"} alt={branch.branch}
                       style={{width: '10vw'}}/>
                  <h3>{branch.branch}</h3>
                  <p>{branch.details}</p>
                  <p><strong>Branch Number:</strong> {branch
                      ? branch.branchNumber : 'N/A'}</p>
                  <p><strong>Branch Type:</strong> {branch ? branch.branchType
                      : 'N/A'}</p>
                  <p><strong>Total Employees:</strong> {branch ? formatNumber(
                      branch.totalEmployees) : 'N/A'} people</p>
                </div>

                <div className="emission-info">
                  <div className="emission-card value-card">
                    <h4>Total carbon footprint</h4>
                    <p>tCO2e</p>
                    <p className="emission-value">{formatNumber(
                        branch.emissions.scope1 + branch.emissions.scope2
                        + branch.emissions.scope3)}</p>
                  </div>
                  <div className="emission-card value-card">
                    <h4>Emission scope 1</h4>
                    <p>tCO2e</p>
                    <p className="emission-value">{formatNumber(
                        branch.emissions.scope1)}</p>
                    <p>Direct emissions from owned sources</p>
                  </div>
                  <div className="emission-card value-card">
                    <h4>Emission scope 2</h4>
                    <p>tCO2e</p>
                    <p className="emission-value">{formatNumber(
                        branch.emissions.scope2)}</p>
                    <p>Indirect emissions from the purchased energy</p>
                  </div>
                  <div className="emission-card value-card">
                    <h4>Emission scope 3</h4>
                    <p>tCO2e</p>
                    <p className="emission-value">{formatNumber(
                        branch.emissions.scope3)}</p>
                    <p>Indirect emissions from activities in the value chain</p>
                  </div>
                </div>

                <div className="reduction-initiatives">
                  <h4>Reduction Initiatives</h4>
                  <p className="emission-value">-5,763 tCO2e</p>
                  <p className="equivalent-value">Equivalent to 192,112.7
                    Mangrove (Rhizophora apiculata) in 8 years of lifespan</p>
                  <div className="chart-container">
                    <Line data={dummyData} options={lineOptions}/>
                  </div>
                </div>

                <div className="additional-info">
                  <div className="info-card value-card">
                    <h4>Green project</h4>
                    <p>tCO2e</p>
                    <p className="info-value">{formatNumber(
                        branch.initiatives.emissions)}</p>
                  </div>
                  <div className="info-card value-card">
                    <h4>Electric vehicle</h4>
                    <p>Unit</p>
                    <p className="info-value">{formatNumber(
                        branch.initiatives.electricCars)}</p>
                  </div>
                  <div className="info-card value-card">
                    <h4>Solar panel capacity</h4>
                    <p>kWp</p>
                    <p className="info-value">{formatNumber(
                        branch.initiatives.solarPower)}</p>
                  </div>
                  <div className="info-card value-card">
                    <h4>Tree planting</h4>
                    <p>Trees</p>
                    <p className="info-value">{formatNumber(
                        branch.initiatives.treesPlanted)}</p>
                  </div>
                </div>

                <div className="energy-consumption">
                  <div className="consumption-card value-card">
                    <h4>Electricity usage</h4>
                    <p>kWh</p>
                    <p className="consumption-value">{formatNumber(
                        branch.consumption.electricity)}</p>
                    <p className="percentage better">{formatNumber(
                        branch.reduction.electricity)}% ↓</p>
                  </div>
                  <div className="consumption-card value-card">
                    <h4>Fuel consumptions</h4>
                    <p>Liter</p>
                    <p className="consumption-value">{formatNumber(
                        branch.consumption.fuel)}</p>
                    <p className="percentage better">{formatNumber(
                        branch.reduction.fuel)}% ↓</p>
                  </div>
                </div>

                <div className="footer">
                  <p>Powered by <img
                      src={"https://aquila.is/wp-content/uploads/2024/04/aquila-logo-blue.png"}
                      width="100" alt={"AQUILA"}/></p>
                  <p>Bank OCB</p>
                </div>
              </>
          )}
        </div>
      </div>
  );
};

export default DetailsPanel;
