import React from 'react';
import {MapContainer, Marker, Popup, TileLayer} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './MapView.css'
import L from 'leaflet';
import ocbMarker from './assets/OCB.webp'; // Adjust the path as necessary

const formatNumber = (num) => {
  return new Intl.NumberFormat('en-US', {maximumSignificantDigits: 13}).format(
      num,
  );
}

// Custom icon for the marker
const customIcon = new L.Icon({
  iconUrl: ocbMarker,
  iconSize: [25, 25],
  iconAnchor: [12, 25],
  popupAnchor: [-3, -76],
});

const MapView = ({setSelectedRegion, branches}) => {
  const handleClick = (branch) => {
    setSelectedRegion(branch);
  };

  return (
      <MapContainer center={[10.8, 106.7]} zoom={12}
                    style={{height: '100vh', width: '100vw'}}>
        <TileLayer
            url="https://tile.thunderforest.com/atlas/{z}/{x}/{y}.png?apikey=f031a68f57304e3c9a6d9e9abd7d0c6b"
            // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {branches.map((marker, index) => (
            <Marker
                key={index}
                position={marker.position}
                icon={customIcon}
                eventHandlers={{
                  click: () => {
                    handleClick(marker);
                  },
                }}
            >
              <Popup className="custom-popup">
                <div className="popup-content">
                  <img src={"./ocb-location.jpg"} alt={marker.branch}
                       className="popup-image"
                       style={{maxWidth: "100%", maxHeight: "100%"}}/>
                  <div className="popup-text">
                    <h3>{marker.branch}</h3>
                    <p style={{fontSize: '0.8em'}}>{marker.details}</p>
                    <p className="emission"><span style={{
                      fontSize: '1.2em',
                      color: 'black',
                    }}>{formatNumber(
                        marker.emissions.total)}</span> tCO2e</p>
                    <button className="more-detail-btn">More Detail</button>
                  </div>
                </div>
              </Popup>
            </Marker>
        ))}
      </MapContainer>
  );
};

export default MapView;
