import React, {useState} from 'react';
import MapView from './MapView';
import DetailsPanel from './DetailsPanel';
import './App.css';
import {
  CategoryScale,
  Chart,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from "chart.js";

Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const App = () => {
  const [selectedRegion, setSelectedRegion] = useState(null);

  const branches = [
    {
      branch: "Tòa nhà The Hallmark",
      totalEmployees: 123,
      position: [
        10.7814095,
        106.7184076
      ],
      details: "Tòa nhà The Hallmark, 15 Đ. Trần Bạch Đằng, P. Phú Thuận, Thủ Đức, Thành phố Hồ Chí Minh 70000, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "22-24 Trần Phú",
      totalEmployees: 123,
      position: [
        18.340615,
        105.8939337
      ],
      details: "22-24 Trần Phú, Bắc Hà, Hà Tĩnh, 480000, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "9/17 Quang Trung",
      totalEmployees: 123,
      position: [
        20.4427827,
        106.3376527
      ],
      details: "9/17 Quang Trung, Tổ 2, Thái Bình, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "273 Nguyễn Thị Lưu",
      totalEmployees: 123,
      position: [
        21.2833535,
        106.2025518
      ],
      details: "273 Nguyễn Thị Lưu, Phường Ngô Quyền, Bắc Giang, 230000, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "260 Lê Công Thanh",
      totalEmployees: 123,
      position: [
        20.5423904,
        105.9200416
      ],
      details: "260 Lê Công Thanh, Trần Hưng Đạo, Phủ Lý, Hà Nam, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "265 Đ. Cầu Giấy",
      totalEmployees: 123,
      position: [
        21.034474,
        105.7952316
      ],
      details: "265 Đ. Cầu Giấy, Dịch Vọng, Cầu Giấy, Hà Nội, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "Ngân hàng TMCP Phương Đông (OCB) - CN Hưng Yên",
      totalEmployees: 123,
      position: [
        20.9377256,
        106.0627303
      ],
      details: "76-78-80 Đường Nguyễn Bình, Nhân Hoà, Mỹ Hào, Hưng Yên, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "349 Trần Hưng Đạo",
      totalEmployees: 123,
      position: [
        10.9301972,
        108.1049996
      ],
      details: "349 Trần Hưng Đạo, Bình Hưng, Thành phố Phan Thiết, Bình Thuận, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "256 Quang Trung",
      totalEmployees: 123,
      position: [
        20.432295,
        106.1772198
      ],
      details: "256 Quang Trung, TP. Nam Định, Nam Định 420000, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "24 Đường Hai Bà Trưng",
      totalEmployees: 123,
      position: [
        11.9428676,
        108.4322886
      ],
      details: "24 Đường Hai Bà Trưng, Phường 6, Thành phố Đà Lạt, Lâm Đồng, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "TPBank LiveBank HH Linh Đàm Hoàng Mai",
      totalEmployees: 123,
      position: [
        20.9632572,
        105.8263244
      ],
      details: "tòa HH4B, chung cư HH, P. Linh Đàm, Hoàng Liệt, Hoàng Mai, Hà Nội, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "117 Nguyễn Huệ",
      totalEmployees: 123,
      position: [
        16.4588278,
        107.5979271
      ],
      details: "117 Nguyễn Huệ, Phú Nhuận, Thành phố Huế, Thừa Thiên Huế 530000, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "40c Đ. Hùng Vương",
      totalEmployees: 123,
      position: [
        13.9756314,
        107.997485
      ],
      details: "40c Đ. Hùng Vương, P. Diên Hồng, Thành phố Pleiku, Gia Lai 600000, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "25 Đường Hoàng Văn Thụ",
      totalEmployees: 123,
      position: [
        21.5940466,
        105.8370481
      ],
      details: "25 Đường Hoàng Văn Thụ, Phan Đình Phùng, Thành phố Thái Nguyên, Thái Nguyên, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "850 Trần Hưng Đạo",
      totalEmployees: 123,
      position: [
        20.2585201,
        105.9705041
      ],
      details: "850 Trần Hưng Đạo, Tân Thành, Ninh Bình, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "10 Nguyễn Đăng Đạo",
      totalEmployees: 123,
      position: [
        21.182471,
        106.0691303
      ],
      details: "10 Nguyễn Đăng Đạo, Kinh Bắc, Bắc Ninh, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "345 Đ. Bà Triệu",
      totalEmployees: 123,
      position: [
        21.8541456,
        106.7619136
      ],
      details: "345 Đ. Bà Triệu, Vĩnh Trại, Thành phố Lạng Sơn, Lạng Sơn, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "607 Lê Thánh Tông",
      totalEmployees: 123,
      position: [
        20.9512151,
        107.0874256
      ],
      details: "607 Lê Thánh Tông, P. Bạch Đằng, Thành phố Hạ Long, Quảng Ninh, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "148 Lê Hồng Phong",
      totalEmployees: 123,
      position: [
        10.3582312,
        107.0801269
      ],
      details: "148 Lê Hồng Phong, Phường 7, Thành phố Vũng Tầu, Bà Rịa - Vũng Tàu, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "93 P. Trần Phú",
      totalEmployees: 123,
      position: [
        20.8566141,
        106.6839729
      ],
      details: "93 P. Trần Phú, Cầu Đất, Ngô Quyền, Hải Phòng, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "Tòa nhà Nam Long",
      totalEmployees: 123,
      position: [
        21.022282,
        105.848337
      ],
      details: "66A Trần Hưng Đạo, Hoàn Kiếm, Hà Nội, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "28 P. Cát Linh",
      totalEmployees: 123,
      position: [
        21.0290198,
        105.8306619
      ],
      details: "28 P. Cát Linh, Cát Linh, Đống Đa, Hà Nội, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "34-36 Quang Trung",
      totalEmployees: 123,
      position: [
        16.0752276,
        108.2212715
      ],
      details: "34-36 Quang Trung, Thạch Thang, Hải Châu, Đà Nẵng 550000, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "Ngân hàng TMCP Phương Đông (OCB) - CN Đồng Nai",
      totalEmployees: 123,
      position: [
        10.9473756,
        106.8308087
      ],
      details: "K50/K27 Đ. Võ Thị Sáu, KP7, Thành phố Biên Hòa, Đồng Nai, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "Ngân Hàng TMCP Phương Đông (OCB) - CN Bình Dương",
      totalEmployees: 123,
      position: [
        10.9709047,
        106.6745385
      ],
      details: "233 khu phố 2, Đại lộ Bình Dương, P. Phú Thọ, Thủ Dầu Một, Bình Dương, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "202 Đ. Hùng Vương",
      totalEmployees: 123,
      position: [
        10.553729,
        106.3963585
      ],
      details: "202 Đ. Hùng Vương, Phường 6, Tân An, Long An, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "Vincom Plaza Vị Thanh",
      totalEmployees: 123,
      position: [
        9.783603,
        105.4695087
      ],
      details: "Số 01 Đường 3/2, Khu vực 3, Vị Thanh, Hậu Giang, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "Trần Hưng Đạo",
      totalEmployees: 123,
      position: [
        10.3870805,
        105.4336459
      ],
      details: "Trần Hưng Đạo, Mỹ Bình, Tp. Long Xuyên, An Giang, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "Đ. Nguyễn Huệ",
      totalEmployees: 123,
      position: [
        10.4573664,
        105.6379152
      ],
      details: "Đ. Nguyễn Huệ, Phường 2, Tp. Cao Lãnh, Đồng Tháp, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "81 Phạm Thái Bường",
      totalEmployees: 123,
      position: [
        10.24476,
        105.9780425
      ],
      details: "81 Phạm Thái Bường, Phường 4, Vĩnh Long, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "53 Trần Hưng Đạo",
      totalEmployees: 123,
      position: [
        9.597535299999999,
        105.971755
      ],
      details: "53 Trần Hưng Đạo, Phường 3, Sóc Trăng, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "442 Trần Phú",
      totalEmployees: 123,
      position: [
        9.301377900000002,
        105.7212317
      ],
      details: "442 Trần Phú, Phường 7, Bạc Liêu, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "39 Đ. Hoàng Diệu",
      totalEmployees: 123,
      position: [
        10.7642984,
        106.7056576
      ],
      details: "39 Đ. Hoàng Diệu, Phường 12, Quận 4, Thành phố Hồ Chí Minh, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "Ngân hàng TMCP Phương Đông (OCB) - CN Tân Bình",
      totalEmployees: 123,
      position: [
        10.797733,
        106.658295
      ],
      details: "435G-H, Đ. Hoàng Văn Thụ, Phường 4, Tân Bình, Thành phố Hồ Chí Minh, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "751A Đ. Kha Vạn Cân",
      totalEmployees: 123,
      position: [
        10.8531122,
        106.7544605
      ],
      details: "751A Đ. Kha Vạn Cân, Linh Chiểu, Thủ Đức, Thành phố Hồ Chí Minh, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "252a Đ. Nguyễn Thị Thập",
      totalEmployees: 123,
      position: [
        10.738649,
        106.715341
      ],
      details: "252a Đ. Nguyễn Thị Thập, Bình Thuận, Quận 7, Thành phố Hồ Chí Minh, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "549 Đ. Hồng Bàng",
      totalEmployees: 123,
      position: [
        10.7532282,
        106.6521688
      ],
      details: "549 Đ. Hồng Bàng, Phường 14, Quận 5, Thành phố Hồ Chí Minh, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "Dali Tower",
      totalEmployees: 123,
      position: [
        10.8038835,
        106.6896744
      ],
      details: "24C Đ. Phan Đăng Lưu, Phường 6, Bình Thạnh, Thành phố Hồ Chí Minh, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "127 Đ. Nguyễn Văn Cừ",
      totalEmployees: 123,
      position: [
        21.0417212,
        105.8697181
      ],
      details: "127 Đ. Nguyễn Văn Cừ, Ngọc Lâm, Thường Tín, Hà Nội, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "348 Đường 30 tháng 4",
      totalEmployees: 123,
      position: [
        11.3155417,
        106.0978971
      ],
      details: "348 Đường 30 tháng 4, Phường 3, Châu Thành, Tây Ninh, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "2 Hoàng Quốc Việt",
      totalEmployees: 123,
      position: [
        21.0465094,
        105.8045101
      ],
      details: "2 Hoàng Quốc Việt, Nghĩa Đô, Cầu Giấy, Hà Nội, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "463 Trần Hưng Đạo",
      totalEmployees: 123,
      position: [
        13.7780167,
        109.2272009
      ],
      details: "463 Trần Hưng Đạo, Thành phố Qui Nhơn, Bình Định, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "269 Lê Hồng Phong",
      totalEmployees: 123,
      position: [
        12.6832748,
        108.0410878
      ],
      details: "269 Lê Hồng Phong, Thống Nhất, Thành phố Buôn Ma Thuột, Đắk Lắk, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "14 - 16B Đ. Hoà Bình",
      totalEmployees: 123,
      position: [
        10.0341958,
        105.7847023
      ],
      details: "14 - 16B Đ. Hoà Bình, Tân An, Ninh Kiều, Cần Thơ, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "45 Đ. Lê Duẩn",
      totalEmployees: 123,
      position: [
        10.7811728,
        106.6996089
      ],
      details: "45 Đ. Lê Duẩn, Bến Nghé, Quận 1, Thành phố Hồ Chí Minh, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "golden tower",
      totalEmployees: 324,
      position: [
        10.7889211,
        106.703516
      ],
      details: "golden tower, Đ. Nguyễn Thị Minh Khai, Đa Kao, Quận 1, Thành phố Hồ Chí Minh 700000, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "146 Đ. Lê Văn Sỹ",
      totalEmployees: 123,
      position: [
        10.79231,
        106.671193
      ],
      details: "146 Đ. Lê Văn Sỹ, Phường 10, Phú Nhuận, Thành phố Hồ Chí Minh, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    },
    {
      branch: "Ngân hàng TMCP Phương Đông (OCB) - CN Chợ Lớn",
      totalEmployees: 123,
      position: [
        10.7584589,
        106.6768774
      ],
      details: "419 - 421 Đ. An Dương Vương, Phường 3, Quận 5, Thành phố Hồ Chí Minh 700000, Vietnam",
      emissions: {
        scope1: 736,
        scope2: 15910,
        scope3: 0,
        reduction: -5763,
      },
      initiatives: {
        emissions: 2671,
        electricCars: 1,
        solarPower: 0,
        treesPlanted: 0,
      },
      consumption: {
        electricity: 18287,
        fuel: 310582,
      },
      reduction: {
        electricity: 75.74,
        fuel: 77.82,
      },
    }
  ];

  return (
      <div className="app">
        <DetailsPanel branch={selectedRegion} branches={branches}
                      setSelectedRegion={setSelectedRegion}/>
        <MapView setSelectedRegion={setSelectedRegion} branches={branches}/>
      </div>
  );
};

export default App;
